.sing-up-sing-up {
  gap: 60px;
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #F9FBFD;
}
.sing-up-container {
  gap: 60px;
  height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sing-up-container01 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sing-up-container02 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.sing-up-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.sing-up-text {
  font-size: 28px;
  font-weight: 600;
}
.sing-up-text01 {
  opacity: 0.7;
}
.sing-up-container03 {
  width: 383px;
  height: auto;
  display: flex;
  overflow: hidden;
  box-shadow: 3px 3px 3px 0px rgba(212, 212, 212, 0.14);
  align-items: flex-start;
  border-color: #DAE0EA;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.sing-up-container04 {
  gap: 5px;
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: #DAE0EA;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(35deg, rgba(104,64,165,1) 0%, rgba(148,84,224,1) 39%, rgba(204,56,207,1) 100%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.sing-up-container05 {
  gap: 5px;
  display: flex;
  align-items: flex-end;
}
.sing-up-text03 {
  opacity: 0.5;
  font-size: 12px;
  margin-bottom: 1px;
}
.sing-up-container06 {
  flex: 1;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sing-up-container07 {
  gap: 10px;
  width: 100%;
  display: none;
  align-items: flex-start;
  padding-top: 20px;
  border-color: #DAE0EA;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  background-color: #F9FBFD;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.sing-up-text04 {
  color: rgb(104, 64, 165);
  font-size: 22px;
  font-weight: 600;
}
.sing-up-text05 {
  font-size: 0.9375rem;
}
.sing-up-container08 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 20px;
}
.sing-up-container09 {
  gap: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sing-up-container10 {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sing-up-text06 {
  font-size: 12px;
}
.sing-up-container11 {
  width: 100%;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.sing-up-container12 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.sing-up-textinput {
  width: 305px;
  height: 100%;
}
.sing-up-container13 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(104, 64, 165, 0.3);
}
.sing-up-icon {
  width: 90%;
  height: 90%;
}
.sing-up-container14 {
  fill: #a54040;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-color: #a54040;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
}
.sing-up-icon02 {
  width: 90%;
  height: 90%;
}
.sing-up-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #DAE0EA;
}
.sing-up-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(35deg, rgba(104,64,165,1) 0%, rgba(148,84,224,1) 39%, rgba(204,56,207,1) 100%);
}
.sing-up-container17 {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sing-up-text07 {
  font-size: 12px;
}
.sing-up-container18 {
  width: 100%;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.sing-up-container19 {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.sing-up-textinput1 {
  width: 305px;
  height: 100%;
}
.sing-up-container20 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(104, 64, 165, 0.3);
}
.sing-up-icon04 {
  width: 90%;
  height: 90%;
}
.sing-up-container21 {
  fill: #a54040;
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-color: #a54040;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
}
.sing-up-icon06 {
  width: 90%;
  height: 90%;
}
.sing-up-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-color: #DAE0EA;
}
.sing-up-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: flex-start;
  background-image: linear-gradient(35deg, rgba(104,64,165,1) 0%, rgba(148,84,224,1) 39%, rgba(204,56,207,1) 100%);
}
.sing-up-container24 {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.sing-up-container25 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.sing-up-container26 {
  flex: 0 0 auto;
  width: 17px;
  cursor: pointer;
  height: 17px;
  display: flex;
  overflow: hidden;
  transition: 0.1s;
  align-items: flex-start;
  border-color: #DAE0EA;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.sing-up-container26:hover {
  background-color: #F1F3F9;
}
.sing-up-container27 {
  fill: var(--dl-color-gray-white);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #6840A5;
}
.sing-up-icon08 {
  width: 90%;
  height: 90%;
}
.sing-up-text08 {
  color: rgba(18, 38, 63, 0.7);
}
.sing-up-container28 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: #DAE0EA;
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: #F1F3F9;
}
.sing-up-text09 {
  opacity: 0.4;
}
.sing-up-container29 {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  cursor: pointer;
  height: 35px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-color: #DAE0EA;
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: #6840A5;
}
