.sing-up-successfull-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.sing-up-successfull-container01 {
  gap: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #F9FBFD;
}
.sing-up-successfull-container02 {
  gap: 60px;
  height: 525px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sing-up-successfull-container03 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sing-up-successfull-container04 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.sing-up-successfull-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.sing-up-successfull-text {
  font-size: 28px;
  font-weight: 600;
}
.sing-up-successfull-text1 {
  opacity: 0.7;
}
.sing-up-successfull-container05 {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sing-up-successfull-text2 {
  font-size: 28px;
}
.sing-up-successfull-container06 {
  width: 383px;
  display: flex;
  overflow: hidden;
  box-shadow: 3px 3px 3px 0px rgba(212, 212, 212, 0.14);
  align-items: center;
  border-color: #DAE0EA;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.sing-up-successfull-container07 {
  gap: 5px;
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-color: #DAE0EA;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: linear-gradient(35deg, rgba(104,64,165,1) 0%, rgba(148,84,224,1) 39%, rgba(204,56,207,1) 100%);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.sing-up-successfull-container08 {
  gap: 5px;
  display: flex;
  align-items: flex-end;
}
.sing-up-successfull-text4 {
  opacity: 0.5;
  font-size: 12px;
  margin-bottom: 1px;
}
.sing-up-successfull-container09 {
  gap: 10px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  padding-bottom: 20px;
}
.sing-up-successfull-text5 {
  text-align: center;
  line-height: 1.5;
}
.sing-up-successfull-text8 {
  text-align: center;
  line-height: 1.5;
}
